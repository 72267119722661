import React from "react"
import Card from "./card"

import AudienceCard from "../audience/audienceCard";
import { readableNumber } from '../../../utils/text';
import { subredditRankingCategories } from '../../../utils/reddit';

// Ranking
const Ranking = ({subreddit, collection, rankings}) => {
  // const comparisonSubreddits = collection.collect_collectionitems.map(ci => ci.subreddit);
  // const categories = subredditRankingCategories.filter(c => calculated[c['key']]).map(c => {
  //   // enrich each category with this subreddit's ranking (always assumes higher is better right now)
  //   var allValues = comparisonSubreddits.filter(s => s.calculated && s.calculated[c['key']]).map(s => s.calculated[c['key']]).sort((a,b) => (a < b) ? 1 : ((b < a) ? -1 : 0));
  //   allValues = c.reverse ? allValues.reverse() : allValues;
  //   c['rank'] = allValues.indexOf(calculated[c['key']]) + 1;
  //   c['value'] = calculated[c['key']];
  //   return c;
  // });
  const categoriesToShow = rankings || []; // calculated in gatsby-node.js
  const rankingColors = {
    1: '#FFD700',
    2: '#C0C0C0',
    3: '#CD7F32',
  }

  return (
    <Card title={`Audience Ranking`}>
      <div className="">
        <div className="">
          <div className="bg-gray-600 border border-gray-700 rounded-md">
            <AudienceCard collection={collection} />
          </div>
        </div>

        <div className="mt-4">
          {categoriesToShow.map(c => (
            <div key={c.label} className="flex items-center space-x-2 mb-2">
              <div style={{color: rankingColors[c.rank]}}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
                </svg>
              </div>
              <div className="font-bold text-gray-400">
                #{c.rank}
              </div>
              <div className="font-semibold mr-auto flex-grow">{c.label}</div>
              {!c.hideValues ? (
                <div className="ml-auto text-xs text-gray-500">
                  {c.display ? c.display(subreddit) : ``}
                </div>
              ) : ''}
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}

export default Ranking;
