import React from "react"

import Card from "./card"
import SubmissionTypeIcon from "./submissionTypeIcon"

import { readableNumber } from '../../../utils/text';

// popular posts of subreddit
const Popular = ({subreddit, posts}) => {

  // TODO: add analysis tag (topic, theme)

  return (
    <Card title={`Popular Posts`} subtitle={`in r/${subreddit.name}`}>
      <div className="space-y-2">
        {posts.map(p => (
          <div key={p.link} className="flex items-center">
            <div><SubmissionTypeIcon type={p.type} className={"text-gray-500 h-5 w-5 text-center mr-2"}/></div>
            <div className="overflow-hidden truncate">
              <a href={`${p.link.includes('https://') ? p.link : `https://reddit.com${p.link}`}`}
                rel="nofollow"
                className="border-b-2 border-dotted border-cyan-800 hover:border-cyan-500 overflow-hidden truncate" target="_blank">
                  {p.title}
                </a>
            </div>
            <div className="ml-auto pl-2 flex items-center flex-shrink-0 space-x-4 text-xs text-gray-300 mt-1">
              
              <div>💬 {p.num_comments}</div>
              <div>⬆ {p.score}</div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default Popular;
