import React from "react";

import {
  FireIcon, ThumbUpIcon, QuestionMarkCircleIcon, EmojiSadIcon, EmojiHappyIcon,
  LightBulbIcon, SupportIcon, PuzzleIcon, UsersIcon, CurrencyDollarIcon, ArrowUpIcon,
  CursorClickIcon, GiftIcon, EyeIcon, BookmarkIcon, FolderIcon,
} from '@heroicons/react/outline'

// NOTE! not just for submission types, but others as well
const SubmissionTypeIcon = ({type, className, fill, stroke}) => {

  return (
    <React.Fragment>
      {type === 'text' ? (
        <svg className={`${className || 'h-5 w-5'}`} xmlns="http://www.w3.org/2000/svg" fill={fill || "none"} viewBox="0 0 24 24" stroke={stroke || "currentColor"}>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
      ) : type === 'video' ? (
        <svg className={`${className || 'h-5 w-5'}`} xmlns="http://www.w3.org/2000/svg" fill={fill || "none"} viewBox="0 0 24 24" stroke={stroke || "currentColor"}>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
        </svg>
      ) : type === 'image' ? (
        <svg className={`${className || 'h-5 w-5'}`} xmlns="http://www.w3.org/2000/svg" fill={fill || "none"} viewBox="0 0 24 24" stroke={stroke || "currentColor"}>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
      ) : type === 'link' ? (
        <svg className={`${className || 'h-5 w-5'}`} xmlns="http://www.w3.org/2000/svg" fill={fill || "none"} viewBox="0 0 24 24" stroke={stroke || "currentColor"}>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
        </svg>
      ) : type === 'crosspost' ? (
        <svg className={`${className || 'h-5 w-5'}`} xmlns="http://www.w3.org/2000/svg" fill={fill || "none"} viewBox="0 0 24 24" stroke={stroke || "currentColor"}>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
        </svg>
      ) : type === 'comment' ? (
        <svg className={`${className || 'h-5 w-5'}`} xmlns="http://www.w3.org/2000/svg" fill={fill || "none"} viewBox="0 0 24 24" stroke={stroke || "currentColor"}>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
        </svg>
      ) : type === 'tag' ? (
        <svg className={`${className || 'h-5 w-5'}`} xmlns="http://www.w3.org/2000/svg" fill={fill || "none"} viewBox="0 0 24 24" stroke={stroke || "currentColor"} strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
        </svg>
      ) : type === 'live' ? (
        <svg className={`${className || 'h-5 w-5'}`} xmlns="http://www.w3.org/2000/svg" fill={fill || "none"} viewBox="0 0 24 24" stroke={stroke || "currentColor"} strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
        </svg>
      ) : type === 'removed' ? (
        <svg className={`${className || 'h-5 w-5'}`} xmlns="http://www.w3.org/2000/svg" fill={fill || "none"} viewBox="0 0 24 24" stroke={stroke || "currentColor"} strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414" />
        </svg>
      ) : type === 'deleted' ? (
        <svg className={`${className || 'h-5 w-5'}`} xmlns="http://www.w3.org/2000/svg" fill={fill || "none"} viewBox="0 0 24 24" stroke={stroke || "currentColor"} strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414" />
        </svg>
      ) : type === 'arrows-in' ? (
        <svg className={`${className || 'h-5 w-5'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25" />
        </svg>
      ) : type === 'bookmark' ? (
        <BookmarkIcon className={`${className || 'h-5 w-5'}`} stroke={stroke || "currentColor"} fill={fill || "none"}/>
      ) : type === 'fire' ? (
        <FireIcon className={`${className || 'h-5 w-5'}`} stroke={stroke || "currentColor"} fill={fill || "none"} />
      ) : type === 'thumb-up' ? (
        <ThumbUpIcon className={`${className || 'h-5 w-5'}`} stroke={stroke || "currentColor"} fill={fill || "none"} />
      ) : type === 'puzzle' ? (
        <PuzzleIcon className={`${className || 'h-5 w-5'}`} stroke={stroke || "currentColor"} fill={fill || "none"} />
      ) : type === 'question-mark-circle' ? (
        <QuestionMarkCircleIcon className={`${className || 'h-5 w-5'}`} stroke={stroke || "currentColor"} fill={fill || "none"} />
      ) : type === 'emoji-sad' ? (
        <EmojiSadIcon className={`${className || 'h-5 w-5'}`} stroke={stroke || "currentColor"} fill={fill || "none"} />
      ) : type === 'light-bulb' ? (
        <LightBulbIcon className={`${className || 'h-5 w-5'}`} stroke={stroke || "currentColor"} fill={fill || "none"} />
      ) : type === 'currency-dollar' ? (
        <CurrencyDollarIcon className={`${className || 'h-5 w-5'}`} stroke={stroke || "currentColor"} fill={fill || "none"} />
      ) : type === 'arrow-up' ? (
        <ArrowUpIcon className={`${className || 'h-5 w-5'}`} stroke={stroke || "currentColor"} fill={fill || "none"} />
      ) : type === 'clicked' ? (
        <CursorClickIcon className={`${className || 'h-5 w-5'}`} stroke={stroke || "currentColor"} fill={fill || "none"} />
      ) : type === 'viewed' ? (
        <EyeIcon className={`${className || 'h-5 w-5'}`} stroke={stroke || "currentColor"} fill={fill || "none"} />
      ) : type === 'new' ? (
        <GiftIcon className={`${className || 'h-5 w-5'}`} stroke={stroke || "currentColor"} fill={fill || "none"} />
      ) : type === 'folder' ? (
        <FolderIcon className={`${className || 'h-5 w-5'}`} stroke={stroke || "currentColor"} fill={fill || "none"} />
      ) : (
        <svg className={`${className || 'h-5 w-5'}`} xmlns="http://www.w3.org/2000/svg" fill={fill || "none"} viewBox="0 0 24 24" stroke={stroke || "currentColor"}>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      )}
    </React.Fragment>
  );
}

export default SubmissionTypeIcon;

