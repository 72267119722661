import React, { useState } from "react"

import Card from "./card"
import SelectPopover from '../../common/selectPopover';
import SignupModalTrigger from '../../home/signupModal'

import { styleKeyword } from '../../../utils/text';

// topics discussed
const Topics = ({subreddit, topics, flair}) => {
  var tabOptions = []; // populated with ['Topics', 'Flair'] if they exist

  if (topics && topics.length) tabOptions.push('Topics');
  if (flair && flair.length) tabOptions.push('Flair');

  // set to the first one available
  const [tabOption, setTabOption] = useState(tabOptions[0]);

  if (!tabOption) return ''; // if neither available, skip the card


  const maxTopicsCount = topics && topics.length ? topics[0].count : 1;
  const maxFlairCount = flair && flair.length ? flair[0].count : 1;


  return (
    <Card title={`Popular Topics`} subtitle={`in r/${subreddit.name}`}>
      <div>
        <div className="flex items-center mb-4">
          {/*<div className="text-base font-semibold text-gray-400">
            {tabOption == 'Topics' ? (
              <span>Top {topicsToShow.length} / {topics.length} topics discussed</span>
            ) : tabOption == 'Flair' ? (
              <span>Top {flairToShow.length} / {flair.length} post flair</span>
            ) : 'unknown option'}
          </div>*/}

          <div className="flex ml-auto space-x-2">
            {tabOptions.length >= 2 ? (
              <SelectPopover
                options={tabOptions}
                currentOption={tabOption}
                setCurrentOption={(s) => setTabOption(s)}
                labelField={undefined}
              />
            ) : ''}
          </div>
        </div>

        {tabOption == 'Topics' ? (
          <div>
            {topics.map((t, i) => (
              <div key={t.label} className="flex items-center mb-1 text-sm group">
                <div className="mr-2 w-7 text-center font-bold text-gray-600">
                  #{i + 1}
                </div>
                <div className="flex-1 bg-transparent rounded-sm relative flex-shrink-0 h-7">
                  <div className="absolute bg-gray-800 opacity-50 bg-gray-700 rounded-sm p-1 h-full" style={{width: (100 * t.count / maxTopicsCount) + '%'}}></div>
                  <div className="absolute p-1 px-2 text-white max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                    <h3 className="font-bold text-sm">{t.label}</h3>
                    {t.topKeywordPost || t.topPost ? (
                      <div className="ml-0.5 text-xs text-gray-400 whitespace-nowrap overflow-ellipsis truncate"
                        dangerouslySetInnerHTML={{
                          __html: styleKeyword(`: "${(t.topKeywordPost || t.topPost).title}"`, t.label, "bg-transparent text-gray-300 text-bold border-b border-dotted border-gray-400")
                        }}
                      ></div>
                    ) : ''}
                  </div>
                </div>
                <div className={`ml-3 text-right ${false ? 'text-gray-300 font-semibold' : 'text-gray-500'}`}>
                  <SignupModalTrigger initiator="subreddit-topics">
                    <span className="text-cyan-500 font-semibold cursor-pointer hover:underline">
                      {t.count} {t.count === 1 ? 'post' : 'posts'}
                    </span>
                  </SignupModalTrigger>
                </div>
              </div>
            ))}
          </div>
        ) : tabOption == 'Flair' ? (
          <div>
            {flair.map((t, i) => (
              <div key={t.label} className="flex items-center mb-1 text-sm group">
                <div className="mr-2 w-7 text-center font-bold text-gray-600">
                  #{i + 1}
                </div>
                <div className="flex-1 bg-transparent rounded-sm relative flex-shrink-0 h-7">
                  <div className="absolute bg-gray-800 opacity-50 bg-gray-700 rounded-sm p-1 h-full" style={{width: (100 * t.count / maxFlairCount) + '%'}}></div>
                  <div className="absolute p-1 px-2 text-white max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                    <div className="font-bold">{t.label}</div>
                    {t.topPost ? (
                      <div className="ml-0.5 text-xs text-gray-400 whitespace-nowrap overflow-ellipsis truncate">
                        : "{t.topPost.title}"
                      </div>
                    ) : ''}
                  </div>
                </div>
                <div className={`ml-3 text-right ${false ? 'text-gray-300 font-semibold' : 'text-gray-500'}`}>
                  <SignupModalTrigger initiator="subreddit-flair">
                    <span className="text-cyan-500 font-semibold cursor-pointer hover:underline">
                      {t.count} post{t.count === 1 ? '' : 's'}
                    </span>
                  </SignupModalTrigger>
                </div>
              </div>
            ))}
          </div>
        ) : 'unknown option'}
        
      </div>
    </Card>
  );
}

export default Topics;
