import React from "react"


const Card = ({title, subtitle, children}) => {
  return (
    <div className="bg-gray-800 p-6 rounded-md">
      <div className="pb-4 mb-4 border-b border-gray-700">
        <h2 className="mt-0 font-bold text-xl">
          {title}{' '}
          {subtitle ? (
            <span className="opacity-50 text-base">{subtitle}</span>
          ) : ''}
        </h2>
      </div>
      <div className="mt-4">
        {children}
      </div>
    </div>
  );
}

export default Card;
