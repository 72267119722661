import React, { useState } from "react"

import {
  FireIcon, ThumbUpIcon, QuestionMarkCircleIcon, EmojiSadIcon, EmojiHappyIcon,
  LightBulbIcon, SupportIcon, PuzzleIcon, UsersIcon, CurrencyDollarIcon, ArrowUpIcon,
  NewspaperIcon, SpeakerphoneIcon,
} from '@heroicons/react/outline'

import Card from "./card"
import SelectPopover from '../../common/selectPopover';
import SignupModalTrigger from '../../home/signupModal'

import { styleKeyword } from '../../../utils/text';

// themes discussed
const Themes = ({subreddit, themes}) => {

  const themeData = {
    'solutions': {'icon': PuzzleIcon, 'description': 'People asking for tools & solutions',},
    'pain': {'icon': EmojiSadIcon, 'description': 'People expressing pain points & frustrations',},
    'resources': {'icon': QuestionMarkCircleIcon, 'description': 'People asking for advice & resources',},
    'ideas': {'icon': LightBulbIcon, 'description': 'People suggesting ideas',},
    'money': {'icon': CurrencyDollarIcon, 'description': 'People talking about spending money',},
    'opportunities': {'icon': ArrowUpIcon, 'description': 'Conversations about things that can be improved',},
    'self': {'icon': SpeakerphoneIcon, 'description': 'People launching products & services',},
    'news': {'icon': NewspaperIcon, 'description': 'Conversations about current news & events',},
  }


  // order by count and find one top post to show for each theme
  const themesToShow = themes
    .map(t => {
      return Object.assign(t, themeData[t.slug])
    });
  const maxthemesCount = themesToShow.length ? themesToShow[0].count : 0;

  // if no themes calculated, don't display anything
  if (maxthemesCount === 0) {return ''}

  return (
    <Card title={`Popular Themes`} subtitle={`in r/${subreddit.name}`}>
      <div>
        {/*<div className="flex items-center mb-4">
          <div className="text-base font-semibold text-gray-400">
            <span>Top themes discussed</span>
          </div>
        </div>*/}

        <div>
          {themesToShow.map((t, i) => (
            <div key={t.slug} className="flex items-center mb-1 text-sm group">
              <div className="mr-2 w-7 text-center font-bold text-gray-600">
                #{i + 1}
              </div>
              <div className="flex-1 bg-transparent rounded-sm relative flex-shrink-0 h-7">
                <div className="absolute bg-gray-800 opacity-50 bg-gray-700 rounded-sm p-1 h-full" style={{width: (100 * t.count / maxthemesCount) + '%'}}></div>
                <div className="absolute p-1 px-2 max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                  <div className={t.count ? 'text-white font-bold' : 'text-gray-500'}>{t.name}</div>
                  {t.topPost ? (
                    <div className="ml-0.5 text-xs text-gray-400 whitespace-nowrap overflow-ellipsis truncate">
                      : "{t.topPost.title}"
                    </div>
                  ) : ''}
                </div>
              </div>
              {t.count ? (
                <div className={`ml-3 text-right ${false ? 'text-gray-300 font-semibold' : 'text-gray-500'}`}>
                  <SignupModalTrigger initiator="subreddit-themes">
                    <span className="text-cyan-500 font-semibold cursor-pointer hover:underline">
                      {t.count} {t.count === 1 ? 'post' : 'posts'}
                    </span>
                  </SignupModalTrigger>
                </div>
              ) : ''}
              
            </div>
          ))}
        </div>
       
        
      </div>
    </Card>
  );
}

export default Themes;
